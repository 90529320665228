@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Avenir LT Std';
    src: url('fonts/avenir/AvenirLTStd-Black.woff2') format('woff2'),
    url('fonts/avenir/AvenirLTStd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('fonts/avenir/AvenirLTStd-Roman.woff2') format('woff2'),
    url('fonts/avenir/AvenirLTStd-Roman.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('fonts/avenir/AvenirLTStd-Book.woff2') format('woff2'),
    url('fonts/avenir/AvenirLTStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('fonts/avenir/AvenirLTStd-Medium.woff2') format('woff2'),
    url('fonts/avenir/AvenirLTStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ansylia';
    src: url('fonts/ansylia/Ansylia-DEMO.woff2') format('woff2'),
    url('fonts/ansylia/Ansylia-DEMO.woff') format('woff'),
    url('fonts/ansylia/Ansylia-DEMO.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
html{
    font-size: 14px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient_bg{
    background: rgba(74,127,203,1);
    background: -moz-linear-gradient(-45deg, rgba(74,127,203,1) 0%, rgba(122,218,216,1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(74,127,203,1)), color-stop(100%, rgba(122,218,216,1)));
    background: -webkit-linear-gradient(-45deg, rgba(74,127,203,1) 0%, rgba(122,218,216,1) 100%);
    background: -o-linear-gradient(-45deg, rgba(74,127,203,1) 0%, rgba(122,218,216,1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(74,127,203,1) 0%, rgba(122,218,216,1) 100%);
    background: linear-gradient(135deg, rgba(74,127,203,1) 0%, rgba(122,218,216,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4a7fcb', endColorstr='#7adad8', GradientType=1 );


    /*background: #232526;  !* fallback for old browsers *!*/
    /*background: -webkit-linear-gradient(to right, #414345, #232526);  !* Chrome 10-25, Safari 5.1-6 *!*/
    /*background: linear-gradient(to right, #414345, #232526); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/

    /*background: #000000;  !* fallback for old browsers *!*/
    /*background: -webkit-linear-gradient(to right, #434343, #000000);  !* Chrome 10-25, Safari 5.1-6 *!*/
    /*background: linear-gradient(to right, #434343, #000000); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/

}

label.floating {
    position: absolute;
    z-index: 1;
    top: 0;
    margin-left: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    -webkit-transition: top .2s ease-in-out, background-color .2s .2s ease-in-out;
    transition: top .2s ease-in-out, background-color .2s .2s ease-in-out;
}


input:focus, textarea:focus {
    outline: none;
}

.input_group input:placeholder-shown + label.floating {
    top: 50%;
    background-color: transparent;
}
.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}
.slick-slide>div{
    height: 100%;
}

.headroom-wrapper{
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.headroom {
    top: 0;
    left: 0;
    right: 0;
    @apply w-full px-2 py-2 md:px-4 transition-all  bg-transparent shadow-none transition-all;
}
.headroom--unfixed {
    /*position: relative;*/
    transform: translateY(0);
    background-color: transparent;
}
.headroom--unpinned #logo, .headroom--pinned #logo{
    @apply scale-90;
}
.headroom--unpinned #topNavSection, .headroom--pinned #topNavSection{
    /*@apply border-light;*/
}
.headroom--scrolled {
    transition: all 250ms ease-in-out;

}
.headroom--unpinned {
    position: fixed;
    transform: translateY(0);
    @apply bg-white shadow-md;
}
.headroom--pinned {
    position: fixed;
    transform: translateY(0%);
    @apply bg-white shadow-md;
}
body{
    overflow-x: hidden;
}
svg{
    color: inherit;
    fill: currentColor;
}
#navs a.active{
    @apply text-red-500;
}
@media screen and (max-width: 768px){
    #cover_circle_2{
        top: 60%;
    }
}

@media screen and (max-height: 800px) {
    #vertical_cover{
        justify-content: space-evenly;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
    #vertical_cover #cover_circle_1{

    }
    #vertical_cover #cover_circle_2{

    }
}

.ReactModal__Overlay{
    z-index: 99999;
}